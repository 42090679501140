// App.js
import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const App = () => {
    const [qrCodeResult, setQrCodeResult] = useState(null);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [pharmacyId, setPharmacyId] = useState('1234'); // Default pharmacy ID
    const [pharmacyName, setPharmacyName] = useState('Test Pharmacy'); // Default pharmacy name

    useEffect(() => {
        // Extracting parameters from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('pharmacyId');
        const name = urlParams.get('pharmacyName');

        // Update state only if URL parameters are provided
        if (id) setPharmacyId(id);
        if (name) setPharmacyName(name); // Use pharmacyName from URL if available

        const scanner = new Html5QrcodeScanner(
            "qr-reader",
            {
                fps: 10,
                qrbox: { width: 250, height: 250 }
            }
        );

        const onScanSuccess = (decodedText) => {
            setQrCodeResult(decodedText); // Update state with QR code text
            scanner.clear(); // Stop the scanner after successful scan
        };

        const onScanFailure = (error) => {
            console.warn(`QR scan error: ${error}`);
        };

        scanner.render(onScanSuccess, onScanFailure);

        // Cleanup scanner on component unmount
        return () => {
            scanner.clear();
        };
    }, []);

    // Email validation
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        return regex.test(email);
    };

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);
        setIsEmailValid(validateEmail(value)); // Update email validity on change
    };

    const handleSubmit = () => {
        setShowPopup(true); // Show the popup with the information
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
            {/* Banner Section */}
            <div style={{
                backgroundColor: '#4CAF50', // Green background
                color: 'white', // White text color
                padding: '10px 20px',
                width: '100%',
                textAlign: 'center',
                fontSize: '18px',
                marginBottom: '20px'
            }}>
                Welcome to {pharmacyName} notification registration page
            </div>

            <h2>Please scan your prescription bag QR code</h2>
            <div id="qr-reader" style={{ width: '250px', height: '250px', margin: '20px 0' }}></div>
            <div style={{ width: '300px', textAlign: 'center' }}>
                {qrCodeResult ? (
                    <>
                        <h3>Details successfully scanned!</h3>
                        <h3>Now please tell us your email address:</h3>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Enter your email"
                            style={{ margin: '10px 0', padding: '5px', width: '80%' }}
                        />
                        <button
                            onClick={handleSubmit}
                            disabled={!isEmailValid} // Disable until email is valid
                            style={{ padding: '5px 10px', marginTop: '10px' }}
                        >
                            Submit
                        </button>
                    </>
                ) : (
                    <p>Awaiting scan...</p>
                )}
            </div>

            {showPopup && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #ccc',
                    padding: '20px',
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}>
                    <h3>Submission Details</h3>
                    <p><strong>Pharmacy Name:</strong> {pharmacyName}</p>
                    <p><strong>Pharmacy ID:</strong> {pharmacyId}</p>
                    <p><strong>QR Code Content:</strong> {qrCodeResult}</p>
                    <p><strong>Email Address:</strong> {email}</p>
                    <button onClick={() => setShowPopup(false)} style={{ marginTop: '10px' }}>
                        OK
                    </button>
                </div>
            )}
            {showPopup && <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 999
            }}></div>}
        </div>
    );
};

export default App;
